//*// TAGS //*//
#category {
  ul {
    font-size: 12px;
    list-style: none;
    margin: 0;
    padding: 0;
  }
  li {
    display: inline-block;
    margin: 0 5px 6px 0;
  }
}
#categories-filed-under {
  display: block;
  font-size: 14px;
  font-weight: bold;
  margin-bottom: 8px;
}
.link-category {
  border-color: #981a5a;
  border-style: solid;
  border-width: 1px 5px 1px 1px;
  color: #000;
  display: block;
  padding: 2px 14px 4px 8px;
  position: relative;
  transition: all 0.4s cubic-bezier(0.42, 0, 0.58, 1);
  white-space: nowrap;
  &:hover,
  &:focus {
    text-decoration: none;
  }
  &:hover {
    color: #fff;
  }
  &:hover:after {
    right: calc(~'100% - 3px');
    transition: all 0.4s cubic-bezier(0.42, 0, 0.58, 1);
  }
  &:after {
    background-color: #981a5a;
    border-radius: 50%;
    content: '';
    display: block;
    height: 8px;
    position: absolute;
    right: -4px;
    top: calc(~'50% - 4px');
    width: 8px;
  }
  &:hover:before {
    left: auto;
    right: 0;
    width: 100%;
  }
  &:before {
    background: #981a5a;
    content: '';
    display: block;
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    transition: all 0.4s cubic-bezier(0.42, 0, 0.58, 1);
    width: 0;
    z-index: -1;
  }
}

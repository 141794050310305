//*// FORMS //*//

//*// Reset fonts for relevant elements
input,
button,
select,
textarea {
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
}
fieldset {
  border: 0;
}

textarea,
input[type="text"],
input[type="password"] {
  border-radius: 0;
  border: 1px solid #ccc;
  color: #000;
  display: block;
  padding: 6px 12px;
  width: 100%;
}


// Normalize non-controls
// Restyle and baseline non-control form elements.
fieldset {
  padding: 0;
  margin: 0;
  border: 0;
  min-width: 0;
}

legend {
  display: block;
  width: 100%;
  padding: 0;
  /* margin-bottom: @plone-line-height-computed; */
  /* font-size: (@plone-font-size-base * 1.5); */
  line-height: inherit;
  /* color: @plone-legend-color; */
  border: 0;
  /* border-bottom: 1px solid @plone-legend-border-color; */
  /* font-weight: @plone-font-weight-light; */
}

label {
  display: inline-block;
  // Force IE8 to wrap long content (see https://github.com/twbs/bootstrap/issues/13141)
  // and 95% to fit labels with fieldhelp in the same line as leading checkbox.
  max-width: 95%;
  vertical-align: top;
  /* margin-bottom: @plone-padding-base-vertical; */
  font-weight: bold;
}


// Normalize form controls
//
// While most of our form styles require extra classes, some basic normalization
// is required to ensure optimum display with or without those classes to better
// address browser inconsistencies.
// Override content-box in Normalize (* isn't specific enough)
/* input[type="search"] { */
  /* .box-sizing(border-box); */
/* } */

// Position radios and checkboxes better
input[type="radio"],
input[type="checkbox"] {
  margin: 4px 0 0;
  margin-top: 1px \9; // IE8-9
  line-height: normal;
}

// Set the height of file controls to match text inputs
input[type="file"] {
  display: block;
}

// Make range inputs behave like textual form controls
input[type="range"] {
  display: block;
  width: 100%;
}

// Make multiple select elements height not fixed
select[multiple],
select[size] {
  height: auto;
  min-width: 10em;
}
/* select[size="1"] { */
  /* height: @plone-input-height-base; */
/* } */

// Focus for file, radio, and checkbox
/* input[type="file"]:focus, */
/* input[type="radio"]:focus, */
/* input[type="checkbox"]:focus { */
  /* .tab-focus(); */
/* } */

// Adjust output element
/* output { */
  /* display: block; */
  /* padding-top: (@plone-padding-base-vertical + 1); */
  /* font-size: @plone-font-size-base; */
  /* line-height: @plone-line-height-base; */
  /* color: @plone-input-color; */
/* } */

input[type="text"],
input[type="number"],
input[type="email"],
input[type="password"],
textarea,
select {
  display: block;
  width: 100%;
  box-sizing: border-box;
  &[disabled],
  &[readonly],
  fieldset[disabled] & {
    cursor: not-allowed;
    opacity: 1;
  }
}
// Reset height for `textarea`s
textarea {
  min-height: 9em;
  min-width: 10em;
  resize: vertical; //as default, only vertical resize
  &[name='form.widgets.IRichText.text'] {
    min-height: 12em;
  }
}
// Reset width for `select` elements
select {
  width: unset;
  display: inline-block;
}

// Search inputs in iOS
//
// This overrides the extra rounded corners on search inputs in iOS so that our
// `.form-control` class can properly style them. Note that this cannot simply
// be added to `.form-control` as it's not specific enough. For details, see
// https://github.com/twbs/bootstrap/issues/11586.

input[type="search"] {
  -webkit-appearance: none;
}


// Special styles for iOS temporal inputs
//
// In Mobile Safari, setting `display: block` on temporal inputs causes the
// text within the input to become vertically misaligned.
// As a workaround, we set a pixel line-height that matches the
// given height of the input. Since this fucks up everything else, we have to
// appropriately reset it for Internet Explorer and the size variations.

/* input[type="date"], */
/* input[type="time"], */
/* input[type="datetime-local"], */
/* input[type="month"] { */
  /* line-height: @plone-input-height-base; */
  /* // IE8+ misaligns the text within date inputs, so we reset */
  /* line-height: @plone-line-height-base ~"\0"; */
/* } */

// Apply same disabled cursor tweak as for inputs
input[type="radio"],
input[type="checkbox"] {
  &[disabled],
  fieldset[disabled] & {
    cursor: not-allowed;
  }
}

//*// Form field
.field {
  margin-bottom: 15px;
  // each option in a multi-checkbox field should be on a separate line
  span.option {
    display: block;
  }
}

.field.error {
  /* .form-control-validation(@plone-state-error-text; @plone-state-error-text; @plone-state-error-bg); */
  /* background: lighten(@plone-state-error-bg,20%); */
  /* .box-shadow(0 0 0 5px lighten(@plone-state-error-bg,20%)); */
  /* > label, div.error { */
    /* color: @plone-toolbar-private-color; */
  /* } */
  div.error {
    font-size: 14px;
    color: #f00;
    &::before {
      background-color: #f00;
      content: "×";
      margin-right: 5px;
      display: inline-block;
      vertical-align: middle;
      border-radius: 50%;
      text-align: center;
      color: #fff;
      width: 16px;
      height: 16px;
      line-height: 14px;
      top: -1px;
      position: relative;
    }
  }
  input,textarea,select {
    border-color: #f00;
    &:focus {
      box-shadow: 0 0 8px lighten(#f00,40%);
    }
  }
}

.formHelp {
  display: block;
  font-size: 14px;
  margin-top: 0;
  margin-bottom: 0;
  color: lighten(#000, 40%);
  font-weight: 400;
  font-style: italic;
}

.formControls {
  input[type="submit"] {
    .btn;
    .btn_defaults;
    background-color: #fff;
    margin-bottom: 20px;
    &:hover {
      cursor: pointer;
    }
  }
}

// don't show formControls in modal body,
// because the actual controls get moved to the footer
.plone-modal-body .formControls {
  border: none;
  background: transparent;
  padding: 0;
  box-shadow: none;
}

//*// Required
label .required {
  &::after{
    color: #f00;
    content:"•";
    font-size: 100%;
    line-height: 0;
    position: relative;
    margin-left: -4px;
  }
}

//*// Input + Submit groups (manual because we still have no standard for this)
.searchField.portlet-search-gadget,
#sharing-user-group-search,
#select-rules  {width: 100%; display: inline-block;}

/* .searchField.portlet-search-gadget + .searchButton, */
/* #sharing-user-group-search + #sharing-search-button, */
/* #select-rules + input  {position: relative; float:right;margin-top: -@plone-input-height-base;} */



//*// Highlighted search
/* #search-results li {margin: @plone-padding-base-horizontal 0; */
  /* .croppedDescription {margin-bottom: 0;} */
/* } */
.highlightedSearchTerm {
  background: #fea;
  box-shadow: 0 0 1px 1px #fea;
}
.search-date-options > div, .search-type-options > div {
display: inline-block;
margin-right: 12px;
}
/* .optionsToggle label {font-weight: 300; color:@plone-gray;} */

.ordered-selection-field{
  td {
    vertical-align: middle;
    text-align: center;
  }
  td button{
    display: block;
    margin: auto;
  }
}

.folder-factories{
  list-style:none;
}

//*// Correct input + label on plone structure widgets
.widget input + label {
  display: inline;
}


label {
    display: inline-block;
    max-width: 95%;
    vertical-align: top;
    margin-bottom: 6px;
    font-weight: bold;
  }

//*// PAGINATION //*//
@plone-line-height-base: 1.556rem;
@plone-pagination-bg: #fff;
@plone-pagination-border: 1px solid #fff;
@plone-pagination-color: #000;

@plone-pagination-hover-bg: #fff;
@plone-pagination-hover-border: 1px solid #000;
@plone-pagination-hover-color: #000;

@plone-pagination-active-bg: #000;
@plone-pagination-active-border: 1px solid #000;
@plone-pagination-active-color: #fff;

@plone-pagination-disabled-bg: #fcfcfd;
@plone-pagination-disabled-border: 0;
@plone-pagination-disabled-color: #ccc;

.pagination {
  margin: 52px 0;
  text-align: center;
  ul {
    display: inline-block;
    list-style: none;
    margin: 0;
    padding: 0;
  }
  li {
    display: block;
    float: left; // Collapse white-space
    font-weight: bold;
    margin: 0 3px;
    padding: 0;
    position: relative;
    > a,
    > span {
      background-color: @plone-pagination-bg;
      border-radius: 18px;
      border: @plone-pagination-border !important; // AAARG, "#content-core a" is taking precedence and messing it up! !important needed.
      color: @plone-pagination-color;
      line-height: @plone-line-height-base;
      padding: 4px 10px;
      text-decoration: none;
      .template-folder_contents & {
        border: 1px solid #ccc !important;
        background-color: transparent;
        border-radius: 4px;
      }
    }
    > a {
      &:hover,
      &:focus {
        box-shadow: 0 1px 2px rgba(0,0,0,0.05);
        background-color: @plone-pagination-hover-bg;
        border: @plone-pagination-hover-border !important;
        color: @plone-pagination-hover-color;
      }
    }
    &.active > span {
      background-color: @plone-pagination-active-bg;
      border-radius: 18px;
      border: @plone-pagination-active-border !important;
      color: @plone-pagination-active-color;
      cursor: default;
      z-index: 2;
    }
    &.disabled > span { //just in case, not used in plone
      background-color: @plone-pagination-disabled-bg;
      border: @plone-pagination-disabled-border !important;
      color: @plone-pagination-disabled-color;
      cursor: default;
    }
    &.previous,
    &.next {
      font-weight: normal;
    }
  }
}

// mobile pagination only shows next and previous as default behaviour
@media (max-width: 767px) {
  .pagination {
    li {
      display: none;
    }
    li.previous,
    li.next {
      display: inline;
      a {
        background-color: #000;color: #fff;
      }
    }
  }
}

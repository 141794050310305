//*// SCAFFOLDING //*//
html {
  font-size: 16px;
  height: 100%;
  line-height: 22px;
}
body {
  display: flex;
  flex-direction: column;
  font-family: 'Lato', sans-serif;
  margin: 0;
  min-height: 100%;
  padding: 0;
}
.main {
  flex: 1 0 auto;
  margin-bottom: 50px;
}

h1 {
  font-size: 26px;
  line-height: 30px;
  margin: 0 0 20px;
  overflow: hidden;
  text-align: center;
  text-overflow: ellipsis;
  text-transform: uppercase;
  @media (min-width: 768px) {
    font-size: 32px;
    line-height: 40px;
    margin: 0 0 30px;
  }
}
h2 {
  font-size: 24px;
  line-height: 30px;
  margin: 0 0 10px;
  @media (min-width: 768px) {
    font-size: 20px;
    line-height: 26px;
  }
}
.description {
  font-size: 20px;
  font-weight: bold;
  line-height: 26px;
  margin: 0 0 20px;
  text-align: center;
  @media (min-width: 768px) {
    margin: 0 0 30px;
  }
}

p {
  margin: 0 0 20px;
}

ul {
  margin: 0 0 20px;
}
.list--soclink {
  align-items: center;
  display: flex;
  justify-content: center;
  list-style: none;
  margin: 10px 0;
  padding: 0;
  @media (min-width: 768px) {
    justify-content: flex-end;
    margin: 0;
  }
  li {
    margin: 0 10px;
    @media (min-width: 768px) {
      margin-right: 20px;
    }
  }
  svg {
    display: block;
  }
}

.mce-panel {
  box-shadow: none !important;
}
.mce-tinymce {
  border-radius: 4px 4px 0 0 !important;
}
body#tinymce {
  padding: 6px 12px;
}

//*// Links

a {
  color: @plone-link-color;
  text-decoration: none;
  &:hover,
  &:focus {
    color: @plone-link-hover-color;
    text-decoration: underline;
  }
}
.btn {
  display: inline-block;
  line-height: 1;
  padding: 7px 25px;
}
.btn_news {
  background-color: #8b154c;
  color: #fff;
  font-size: 12px;
  text-transform: uppercase;
}
.btn_defaults {
  border: 1px solid #1b0e2c;
  color: #1b0e2c;
  font-size: 12px;
  font-weight: bold;
  text-transform: uppercase;
  &:hover {
    color: #fff;
    background-color: #1b0e2c;
    text-decoration: none;
  }
}

//*// Figures
figure {
  margin: 0;
}

//*// Images
.leadImage {
  float: right;
  margin: 4px 0 20px 20px;
}
img {
  vertical-align: middle;
}
.image-inline {
  height: auto;
  width: 100%;
}

// Horizontal rules
hr {
  border-top: 1px solid @plone-hr-border;
  border: 0;
  margin-bottom: @plone-line-height-computed;
  margin-top:    @plone-line-height-computed;
}

// Only display content to screen readers
//
// See: http://a11yproject.com/posts/how-to-hide-content/

.sr-only, .hiddenStructure,
.crud-form .header-select{
  background-color: #000;
  border: 0;
  clip: rect(0,0,0,0);
  color: #fff;
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
}
.sr-only a, .hiddenStructure a {
  background-color: #000;
  color: #fff;
}

// Use in conjunction with .sr-only to only display content when it's focused.
// Useful for "Skip to main content" links; see http://www.w3.org/TR/2013/NOTE-WCAG20-TECHS-20130905/G1
// Credit: HTML5 Boilerplate
.sr-only-focusable {
  &:active,
  &:focus {
    clip: auto;
    height: auto;
    margin: 0;
    overflow: visible;
    position: static;
    width: auto;
  }
}
.glyphicon {
  display: none;
}

//*// VIEWS //*//

//summary view
.tile {
  border-bottom: 1px solid #eee;
  margin: 40px 0;
  padding: 0 0 40px;
  @media (min-width: 768px) {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    .section-cases & {
      align-items: center;
    }
  }
}
.tile__image {
  margin: 0 0 20px 0;
  @media (min-width: 768px) {
    margin: 0 20px 0 0;
  }
}
.tile__header {
  margin: 0 0 10px;
  a {
    color: #000;
  }
}
.tile__date {
  font-size: 14px;
  line-height: 1;
  margin: 0 0 10px;
  .section-cases & {
    display: none;
  }
}
